const automationTestingCompanies = [177330];
const ctCompanies = [
  100552, // CT dev testing
  100683, // QA testing
  177612, // Tech consulting
  100201, // CloudTalk LIVE
  137923, // Martina Redajova
  158135, // Matej Kovar
  158951, // CSM
  182451, // Sales
  168807, // L2
  115777, // L1
];

const dialerBetaCompanies = [
  213276, 264979, 161193, 223137, 288571, 243425, 183911, 222641, 138577,
  130877, 215988, 199805, 200061, 288539, 290253, 291383, 297813, 217084,
  239377, 256363, 280245, 280295, 291463, 291517, 292799, 299277, 159489,
  166503, 285055, 296683, 162091, 202783, 277153,
];

const internalCompanies = [...automationTestingCompanies, ...ctCompanies];

export const featureFlagsConfig = {
  recordingControlEnabled: {
    companies: [...internalCompanies, 115197, 143043, 137923, 175701, 237403],
    users: [],
  },
  doNotShowAlreadyRegisteredPage: {
    companies: [],
    users: [243857, 15433, 266175, 434275],
  },

  anonymousCalls: {
    companies: [
      ...internalCompanies,
      16880,
      152459,
      176151,
      219639,
      204235,
      175701,
      237403,
      258913,
      252375,
      215988,
      252375,
      275663,
      160097,
      146693,
      244027,
      254463,
      244601,
      285077,
      298655,
      297769,
      301169,
    ],
    users: [],
  },
  dialer: {
    companies: [...internalCompanies, ...dialerBetaCompanies],
    users: [],
  },
};

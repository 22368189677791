import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, distinctUntilChanged, take, tap } from 'rxjs';
import { Socket, io } from 'socket.io-client';

import { environment } from '../../../../environments/environment';
import { LoggerUtil } from '../../../_shared/utils/logger.util';
import { AuthenticationService } from '../auth/authentication.service';

const EXPIRED_TOKEN_ERROR_MESSAGE = 'jwt expired';

interface SocketConnectionError {
  data?: string;
  message?: string;
  stack?: string;
}

type EventListener<T = unknown> = (
  data: T,
  ack: (response: string) => void,
) => void;

@Injectable()
export class SocketService implements OnDestroy {
  private socket?: Socket;
  private listeners: Map<string, EventListener[]> = new Map();
  private tokenSubscription: Subscription;

  constructor(private authenticationService: AuthenticationService) {
    this.tokenSubscription = this.authenticationService.accessTokenChange$
      .pipe(
        distinctUntilChanged(),
        tap(() => {
          this.connect();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe();
    }
    this.disconnect();
  }

  /**
   * Connect the user to the socket server
   * If the token is not valid, we don't need to connect
   * If the socket is already connected, we don't need to connect
   */
  connect(): void {
    if (!this.authenticationService.isTokenValid()) {
      return;
    }

    if (this.isConnected) {
      return;
    }

    if (this.socket) {
      this.removeAllListeners();
    }

    const extraHeaders = {
      authorization: `Bearer ${this.authenticationService.accessToken}`,
      'ngsw-bypass': 'true',
    };

    this.socket = io(environment.socketUrl, {
      query: {
        will_use_call_monitor: true,
        will_use_webhooks: true,
      },
      transportOptions: {
        polling: {
          extraHeaders,
        },
      },
      reconnectionDelay: 60 * 1000, // 60s
      reconnectionDelayMax: 75 * 1000, // 75s
    });

    this.applyListeners();
    this.handleConnectionErrors();
  }

  public removeAllListeners(): void {
    if (this.socket) {
      this.socket.removeAllListeners();
    }
  }

  /**
   * Disconnect the socket
   */
  disconnect(): void {
    if (this.socket) {
      this.offConnectionErrors();
      this.socket.disconnect();
      this.socket = undefined;
    }
  }

  /**
   * Check if the socket is connected
   */
  public get isConnected(): boolean {
    return this.socket?.connected ?? false;
  }

  /**
   * Register a custom event handler
   */
  on<T>(evt: string, callback: EventListener<T>): void {
    if (typeof callback !== 'function') {
      throw new Error('You need to define a callback to listen to events');
    }

    if (!this.listeners.has(evt)) {
      this.listeners.set(evt, []);
    }

    this.listeners.get(evt)?.push(callback);

    // Register the listener on the socket
    if (this.socket) {
      this.socket.on(evt, (data: T, ack: (response: string) => void) => {
        callback(data, ack);
      });
    }
  }

  /**
   * Remove an event handler
   */
  off<T>(evt: string, callback?: EventListener<T>): void {
    if (this.listeners.has(evt)) {
      if (callback) {
        const updatedListeners = this.listeners
          .get(evt)
          ?.filter(l => l !== callback);
        this.listeners.set(evt, updatedListeners || []);
      } else {
        this.listeners.delete(evt);
      }
    }

    if (this.socket) {
      if (callback) {
        this.socket?.off(evt, callback);
      } else {
        this.socket?.off(evt);
      }
    }
  }

  /**
   * Apply all registered listeners to the new socket connection
   */
  private applyListeners(): void {
    if (this.socket) {
      this.listeners.forEach((listeners, event) => {
        listeners.forEach(listener => {
          this.socket?.on(event, listener);
        });
      });
    }
  }

  /**
   * Emit an event to the server
   */
  emit<T>(evt: string, data?: unknown, callback?: (args: T) => void): void {
    if (!this.socket) {
      LoggerUtil.error('[SocketService]: emit() Socket is not initialized');
      return;
    }
    this.socket?.emit(evt, data, callback);
  }

  /**
   * Handle expired tokens by refreshing token but not forcing reconnection
   */
  handleTokenExpired() {
    this.authenticationService
      .authRefreshToken()
      .pipe(take(1))
      .subscribe({
        next: () => {
          // We're no longer automatically reconnecting on token change
          // The socket will continue using the existing connection
          LoggerUtil.info(
            '[SocketService]: Token refreshed successfully, continuing with existing connection',
          );
        },
        error: (err: HttpErrorResponse) => {
          LoggerUtil.warn('[SocketService]: Token refresh failed', {}, err);
          this.authenticationService.navigateToLogin(
            true,
            true,
            'SocketService:handleTokenExpired',
          );
        },
      });
  }

  private offConnectionErrors(): void {
    this.off('connect_error');
    this.off('reconnect_error');
    this.off('reconnect_failed');
  }

  /**
   * Set up default event handling for errors
   */
  private handleConnectionErrors(): void {
    if (this.socket) {
      this.on('connect_error', (event: SocketConnectionError) => {
        if (event.message === EXPIRED_TOKEN_ERROR_MESSAGE) {
          LoggerUtil.warn(
            '[SocketService]: Token expired, attempting to refresh',
            { extraContent: event },
          );
          this.handleTokenExpired();
        }
        LoggerUtil.info(
          '[SocketService]: connection error',
          {
            extraContent: event,
          },
          {},
        );
      });
      this.on('reconnect_error', (event: SocketConnectionError) => {
        LoggerUtil.info(
          '[SocketService]: reconnection error',
          {
            extraContent: { event },
          },
          {},
        );
      });
      this.on('reconnect_failed', (event: SocketConnectionError) => {
        LoggerUtil.info(
          '[SocketService]: reconnection failed',
          {
            extraContent: { event },
          },
          {},
        );
      });
    }
  }
}
